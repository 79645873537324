@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./utils/loading.css";
@import "./utils/form-input.css";
@import "./components/daterangepicker.css";
@import "./components/pace.css";
@import "./components/ripple.css";
@import "./components/select2.css";
@import "./components/toastr.css";
@import "./components/easymde.css";
@import "./components/toggle-switchy.css";
@import "./components/scrollbar.css";
@import "./components/autocomplete.css";
@import "./components/micromodal.css";
@import "./components/buttons.css";
@import "./components/cookie.css";

.svg-icon {
    pointer-events: none;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}

.breadcrumb-item {
    @apply pr-2 text-slate-700;
    &.active {
        @apply font-medium;
    }
    a {
        @apply text-slate-900 underline;
    }
}

.breadcrumb-item + .breadcrumb-item::before {
    @apply inline-block pr-2 text-slate-800;
    content: "/";
}

.sidebar--body .nav-item .nav-link.expanded::after {
    content: " \F0DA";
    font-family: FontAwesome;
    display: inline;
    text-align: right;
    padding-left: 5px;
}

.sidebar--body .nav-item .nav-link.collapsed:hover::after {
    content: " \F0D7";
    font-family: FontAwesome;
    display: inline;
    text-align: right;
    padding-left: 5px;
}

/* .sidebar--body .nav-item .nav-link.collapsed:not(:hover)::after {
    display: none;
} */

.sps--abv {
    background-color: transparent;
}

.sps--blw {
    background-color: rgb(247, 247, 247);
    border-bottom: 1px solid #c5c5c5;
    box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.15);
}

.form-label.required {
    @apply after:content-['*'] after:ml-0.5;
}

.CodeMirror-wrap {
    @apply rounded-b overflow-hidden;
}

.EasyMDEContainer {
    .editor-toolbar {
        @apply rounded-t;
    }
}

.form-input {
    @apply border-slate-300 rounded placeholder-slate-500 placeholder-opacity-75 w-full;

    &:focus {
        @apply border-slate-700 ring-slate-700;
    }

    &:disabled {
        @apply opacity-75 bg-slate-200;
    }
}

.form-select {
    @apply border-slate-300 rounded placeholder-slate-500 placeholder-opacity-75;

    &:focus {
        @apply border-slate-700 ring-1 ring-slate-700;
    }

    &:disabled {
        @apply opacity-75 bg-slate-200;
    }
}

.form-checkbox {
    @apply border-slate-500 rounded text-slate-500;

    &:focus {
        @apply border-slate-500 ring-1 ring-slate-300 ring-opacity-50;
    }

    &:disabled ~ span {
        @apply opacity-75;
    }

    &:indeterminate {
        background: 'url("data:image/svg+xml,%3Csvg viewBox=\'0 0 16 16\' fill=\'white\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'8\' height=\'2\' x=\'4\' y=\'7\' rx=\'1\'/%3E%3C/svg%3E");';
        border-color: 'transparent';
        background-color: 'currentColor';
        background-size: '100% 100%';
        background-position: 'center';
        background-repeat: 'no-repeat';
    }

    &:disabled,
    &:disabled:hover {
        @apply border-slate-200;
    }
}

.is-invalid {
    @apply border-red-500;

    &.form-input:not(:disabled),
    &.form-select:not(:disabled) {
        &:focus {
            @apply border-red-500 outline-none ring-1 ring-red-500;
        }
    }
}

.invalid-feedback {
    &:hover {
        @apply break-normal overflow-visible;
        white-space: normal;
    }

    @apply absolute text-red-600 bg-red-200 text-xs font-semibold rounded-sm border border-red-400 truncate max-w-[8rem];
    @apply py-[0.2rem] px-2;
    right: 2px;
    top: -2px;
    z-index: 9;
}

.has-error {
    .form-label {
        @apply text-red-500;
    }
}

.form-group {
    @apply relative w-full;
    &.loading::after {
        @apply bg-transparent border-2 border-blue-600 left-auto top-auto right-4;
        /* bottom: calc(50% - 1.5em); */
        bottom: calc((1rem / 2) + .25rem);
    }
}
/* .pending {
    padding-right: 2rem;
    position: relative !important;
    pointer-events: none;

    &::after {
        content: '';
        position: absolute !important;
        top: calc(50% - (1rem / 2));
        right: calc(1rem / 2);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 2px solid #cccccc;
        border-top-color: #0077dd;
        z-index: 1;
        display: block;
        animation: loading 500ms infinite linear;
    }
}
@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} */

.form-label {
    @apply block text-sm font-medium mb-2;
}

.form-horizontal {
    .form-label {
        @apply mb-0;
    }
}

.table-sortable > thead > tr > th a:not(.table-header-icon):not(.dropdown-item) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-sortable > thead > tr > th {
    a:not(.table-header-icon):not(.dropdown-item) span {
        @apply flex-1 text-xs;
    }
}

table {
    thead {
        tr {
            th {
                /* font-size: x-small; */
                @apply text-xs;
            }
        }
    }

    tbody {
        tr {
            td .button {
                filter: alpha(opacity=100);
                opacity: 0;
                transition: all .1s ease-out;
            }

            &:hover > td .button {
                filter: alpha(opacity=100);
                opacity: 1.00;
                transition: all .1s ease-out;
            }

            /* &.row-checked {
                @apply text-slate-900 bg-blue-200;
                &:hover {
                    @apply bg-blue-300;
                }
            } */
        }
    }
}

.tippy-content {
    @apply !p-0;
}

.action-button:hover {
    background-color: rgba(0, 0, 0, 10%);
}

.splide__track--nav>.splide__list>.splide__slide {
  @apply !border-2 border-transparent rounded ;
}
.splide__track--nav>.splide__list>.splide__slide.is-active {
  @apply !border-slate-300;
}

#sidenav li.nav-active {
    @apply bg-slate-200;
    &:hover {
        @apply bg-slate-300;
    }
    a {
        @apply text-blue-700 font-medium;
    }
}
