.loading {
    min-height: .8rem;
    pointer-events: none;
    position: relative;
}

.loading::after {
    content: " ";
    animation: loading 500ms infinite linear;
    border-radius: 50%;
    border-right-color: transparent!important;
    border-top-color: transparent!important;
    display: block;
    height: 1em;
    width: 1em;
    position: absolute!important;
    z-index: 1;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
