.ps {
    @apply overflow-hidden;

    >.ps__rail-y {
        @apply w-1 absolute z-10 left-auto right-0 opacity-0 m-px transition-opacity duration-200 bg-gray-100;

        >.ps__thumb-y {
            @apply absolute rounded w-1 left-0 bg-gray-600;
        }
    }

    &.ps--active-y {

        &:hover,
        &:focus {
            >.ps__rail-y {
                opacity: 1;
            }
        }
    }
}

.scrollbar-sm {
    >.ps__rail-y {
        width: 1px;

        >.ps__thumb-y {
            width: 1px;
        }
    }
}

.scrollbar-lg {
    >.ps__rail-y {
        width: 6px;

        >.ps__thumb-y {
            width: 6px;
        }
    }
}
