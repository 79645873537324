.autocomplete-result-list {
    @apply p-0 m-0 border border-gray-300 max-h-[296px] overflow-y-auto bg-white list-none shadow-lg;
    z-index: 9999!important;
}

[data-position=above] {
    .autocomplete-input[aria-expanded=true] {
        @apply border-t-transparent rounded-b rounded-t-none;
        z-index: 2
    }
    .autocomplete-result-list {
        @apply -mb-px border border-b-transparent rounded-t rounded-b-none pt-2;
    }
}

[data-position=below] {
    .autocomplete-input[aria-expanded=true] {
        @apply border-b-transparent rounded-t rounded-b-none;
    }
    .autocomplete-result-list {
        @apply -mt-px border border-t-transparent rounded-b rounded-t-none pb-2;
    }
}

.autocomplete-result {
    @apply cursor-pointer bg-none py-2 px-3;

    &:hover,
    &[aria-selected=true] {
        @apply bg-gray-100;
    }
}

.autocomplete-input {
    @apply border-gray-300 rounded;

    &:disabled {
        @apply opacity-75 bg-gray-200;
    }

    @apply relative;
    @apply flex-1;
}

.autocomplete[data-loading=true]:after {
    content: "";
    @apply absolute border-2 border-gray-50 border-r-gray-700 rounded-full w-5 h-5 right-3 top-[50%] transform translate-y-[50%];
    animation: rotate 1s linear infinite
}

.autocomplete {
    .no-results {
        display: none;
        position: absolute;
        z-index: 1;
        width: 100%;
        top: 100%;
    }
    &.without-results .no-results {
        display: block;
    }
    &.without-results .autocomplete-input:focus {
        border-bottom-color: transparent;
        border-radius: 8px 8px 0 0;
    }
    &.without-results .autocomplete-input:not(:focus)~.no-results {
        display: none;
    }
}

@keyframes rotate {
    0% {
        transform: translateY(-50%) rotate(0deg)
    }

    to {
        transform: translateY(-50%) rotate(359deg)
    }
}
