.btn {
    @apply inline-flex items-center gap-2 font-medium py-2 px-4 rounded transition ease-in-out duration-300 text-sm leading-5;
    &:focus {
        @apply outline-none ring-2;
    }
    &:disabled {
        @apply opacity-25 cursor-default;
    }
}

.btn.loading {
    > * {
        visibility: hidden;
        @apply opacity-0;
    }
    &::after {
        border: 2px solid #ffffff;
        left: calc(50% - (1em / 2));
        top: calc(50% - (1em / 2));
        /* border-right-color: transparent;
        border-top-color: transparent; */
    }
}

.btn-sm {
    @apply py-1 px-2 text-xs;
}

.btn-secondary {
    @apply bg-white text-slate-700 border border-slate-400 focus-within:ring-slate-900 hover:bg-slate-100;
}

.btn-secondary.loading {
    &::after {
        @apply border-2 border-blue-600;
    }
}

.btn-ghost {
    @apply bg-transparent border border-transparent transition-colors duration-300 text-slate-700 dark:text-slate-100 focus-within:ring-slate-900 dark:focus-within:ring-slate-100 disabled:hover:bg-transparent dark:hover:bg-slate-500 hover:bg-slate-300;
}
.btn-ghost.loading {
    &::after {
        border-right-color: transparent;
        border-top-color: transparent;
    }
}

.btn-primary {
    @apply bg-slate-600 text-slate-50 focus-within:ring-slate-900 hover:bg-slate-800;
}

.btn-primary.loading {
    &::after {
        @apply border-2 border-slate-50;
    }
}

.btn-blue {
    @apply border bg-blue-500 text-blue-100;

    &:not(:disabled) {
        &:hover {
            @apply bg-blue-600;
        }
        &:active,
        &:focus {
            @apply bg-blue-600 ring-blue-500;
        }
    }
}
.btn-blue.loading {
    &::after {
        @apply border-2 border-blue-200;
    }
}

.btn-blue-outline {
    @apply bg-transparent border-2 border-blue-300 text-blue-600;

    &:not(:disabled) {
        &:hover,
        &:active {
            @apply bg-blue-100 text-blue-500;
        }

        &:focus {
            @apply bg-blue-100 text-blue-500 ring-blue-500;
        }
    }
}

.btn-blue-outline.loading {
    &::after {
        @apply border-2 border-red-600;
    }
}

.btn-red-outline {

    @apply bg-rose-100 border border-rose-800 text-rose-800 focus-within:ring-rose-600 hover:bg-rose-200;
}
.btn-red-outline.loading {
    &::after {
        @apply border-2 border-rose-600;
    }
}

.btn-red {
    @apply border bg-red-500 text-red-100;
    @apply bg-rose-500 text-white focus-within:ring-rose-600 hover:bg-rose-700;
}

.btn-red.loading {
    &::after {
        @apply border-2 border-rose-50;
    }
}
