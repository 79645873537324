.daterangepicker {
    @apply shadow-lg absolute rounded border border-gray-300 w-[278px] max-w-none p-0 mt-2 top-[100px] left-5 z-[3001] hidden bg-white;

    color: inherit;

    /*
    margin-top: 7px;
    top: 100px;

    z-index: 3001;
    */

    &:before,
    &:after {
        position: absolute;
        display: inline-block;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }
    &:before {
        top: -7px;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #ccc;
    }
    &:after {
        top: -6px;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
    }
    &.opensleft:before {
        right: 9px;
    }
    &.opensleft:after {
        right: 10px;
    }
    &.openscenter {
        &:before {
            left: 0;
            right: 0;
            width: 0;
            margin-left: auto;
            margin-right: auto;
        }
        &:after {
            left: 0;
            right: 0;
            width: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &.opensright {
        &:before {
          left: 9px;
        }

        &:after {
          left: 10px;
        }
    }

    &.drop-up {
        margin-top: -7px;
        &:before {
            top: initial;
            bottom: -7px;
            border-bottom: initial;
            border-top: 7px solid #ccc;
        }

        &:after {
            top: initial;
            bottom: -6px;
            border-bottom: initial;
            border-top: 6px solid #fff;
        }
    }

    &.single .daterangepicker .ranges,
    &.single .drp-calendar {
      float: none;
    }
    &.single .drp-selected {
      display: none;
    }
    &.show-calendar .drp-calendar {
        display: block;
    }

    &.show-calendar .drp-buttons {
        @apply flex items-center text-left gap-2;
    }

    &.auto-apply .drp-buttons {
        display: none;
    }

    .drp-calendar {
        display: none;
        max-width: 270px;
        &.left {
            padding: 8px 0 8px 8px;
        }

        &.right {
            padding: 8px;
        }
        &.single .calendar-table {
            border: none;
        }
    }
    .calendar-table .next span,
    .calendar-table .prev span {
        color: #fff;
        border: solid black;
        border-width: 0 2px 2px 0;
        border-radius: 0;
        display: inline-block;
        padding: 3px;
    }
    .calendar-table .next span {
        transform: rotate(-45deg);
    }
    .calendar-table .prev span {
        transform: rotate(135deg);
    }
    .calendar-table th,
    .calendar-table td {
        white-space: nowrap;
        text-align: center;
        vertical-align: middle;
        min-width: 32px;
        width: 32px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid transparent;
        white-space: nowrap;
        cursor: pointer;
    }
    .calendar-table {
        border: 1px solid #fff;
        border-radius: 4px;
        background-color: #fff;
    }
    .calendar-table table {
        width: 100%;
        margin: 0;
        border-spacing: 0;
        border-collapse: collapse;
    }
    td.available:hover,
    th.available:hover {
        @apply bg-gray-200 border-transparent text-inherit;
        /* background-color: #eee;
        border-color: transparent;
        color: inherit; */
    }
    td.week,
    th.week {
        font-size: 80%;
        color: #ccc;
    }
    td.off,
    td.off.in-range,
    td.off.start-date,
    td.off.end-date {
        background-color: #fff;
        border-color: transparent;
        color: #999;
    }
    td.in-range {
        @apply border-transparent text-gray-700 rounded-none bg-gray-100;
        /* background-color: #ebf4f8;
        border-color: transparent;
        color: #000;
        border-radius: 0; */
    }
    td.in-range:not(.start-date):not(.end-date):hover {
        @apply rounded;
    }
    td.start-date {
        border-radius: 4px 0 0 4px;
    }

    td.end-date {
        border-radius: 0 4px 4px 0;
    }

    td.start-date.end-date {
        border-radius: 4px;
    }
    td.active,
    td.active:hover {
        @apply bg-gray-600 border-transparent text-gray-50;
        /* background-color: #357ebd;
        border-color: transparent;
        color: #fff; */
    }
    th.month {
        width: auto;
    }
    td.disabled,
    option.disabled {
        color: #999;
        cursor: not-allowed;
        text-decoration: line-through;
    }
    select.monthselect,
    select.yearselect {
        font-size: 12px;
        padding: 1px;
        height: auto;
        margin: 0;
        cursor: default;
    }
    select.monthselect {
        margin-right: 2%;
        width: 56%;
    }

    select.yearselect {
        width: 40%;
    }
    select.hourselect,
    select.minuteselect,
    select.secondselect,
    select.ampmselect {
        width: 50px;
        margin: 0 auto;
        background: #eee;
        border: 1px solid #eee;
        padding: 2px;
        outline: 0;
        font-size: 12px;
    }
    .calendar-time {
        text-align: center;
        margin: 4px auto 0 auto;
        line-height: 30px;
        position: relative;
        select.disabled {
            color: #ccc;
            cursor: not-allowed;
        }
    }
    .drp-buttons {
        clear: both;
        padding: 8px;
        border-top: 1px solid #ddd;
        display: none;
        line-height: 12px;
    }
    .drp-selected {
        display: inline-block;
        font-size: 12px;
        padding-right: 8px;
        padding-left: 8px;
        margin-right: auto;
    }
    /* .drp-buttons .btn {
        margin-left: 8px;
        font-size: 12px;
        font-weight: bold;
        padding: 4px 8px;
    } */
    /* &.show-ranges.single.rtl .drp-calendar.left {
        border-right: 1px solid #ddd;
    }

    &.show-ranges.single.ltr .drp-calendar.left {
        border-left: 1px solid #ddd;
    }
    &.show-ranges.rtl .drp-calendar.right {
        border-right: 1px solid #ddd;
    }
    .show-ranges.ltr .drp-calendar.left {
        border-left: 1px solid #ddd;
    } */
    .ranges {
        float: none;
        text-align: left;
        margin: 0;
    }
    &.show-calendar .ranges {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .ranges ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        width: 100%;
    }

    .ranges li {
        @apply ml-1 rounded text-xs font-medium px-3 py-2 cursor-pointer hover:bg-gray-200 hover:text-gray-900;

        &.active {
            @apply bg-gray-100 text-gray-900 hover:bg-gray-200;
        }
    }
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
    .daterangepicker {
        width: auto;
        direction: ltr;
        text-align: left;
        .ranges,
        .drp-calendar {
            float: left;
        }
        .ranges ul {
            width: 140px;
        }
        &.single {
            .ranges ul {
                width: 100%;
            }
            .drp-calendar.left {
                clear: none;
            }
            .ranges,
            .drp-calendar {
                float: left;
            }
            .drp-calendar.left {
                clear: left;
                margin-right: 0;
                .calendar-table {
                    border-right: none;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    padding-right: 8px;
                }
            }
            .drp-calendar.right {
                margin-left: 0;
                .calendar-table {
                    border-left: none;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
}

@media (min-width: 730px) {
    .daterangepicker {
        .ranges {
            width: auto;
            float: left;
        }

        &.rtl .ranges {
            float: right;
        }
        .drp-calendar.left {
            clear: none !important;
        }
    }
}
