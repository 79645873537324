.EasyMDEContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.EasyMDEContainer.sided--no-fullscreen {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.EasyMDEContainer .CodeMirror {
    @apply border border-gray-300 h-full p-3;
    font: inherit;
    z-index: 0;
    word-wrap: break-word;
}

.EasyMDEContainer .CodeMirror-scroll {
    cursor: text;
}

.EasyMDEContainer .CodeMirror-fullscreen {
    background: #fff;
    position: fixed !important;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    z-index: 8;
    border-right: none !important;
    border-bottom-right-radius: 0 !important;
}

.EasyMDEContainer .CodeMirror-sided {
    width: 50% !important;
}

.EasyMDEContainer.sided--no-fullscreen .CodeMirror-sided {
    border-right: none!important;
    border-bottom-right-radius: 0px;
    position: relative;
    flex: 1 1 auto;
}

.EasyMDEContainer .CodeMirror-placeholder {
    opacity: .5;
}

.EasyMDEContainer .CodeMirror-focused .CodeMirror-selected {
    background: #d9d9d9;
}

.editor-toolbar {
    @apply relative p-1 select-none flex items-center gap-1 border border-b-0;
}

.editor-toolbar.fullscreen {
    width: 100%;
    height: 50px;
    padding-top: 4px;
    padding-bottom: 4px;
    box-sizing: border-box;
    background: #fff;
    border: 0;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 9;
}

.editor-toolbar.fullscreen::before {
    width: 20px;
    height: 50px;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
}

.editor-toolbar.fullscreen::after {
    width: 20px;
    height: 50px;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
}

.EasyMDEContainer.sided--no-fullscreen .editor-toolbar {
    width: 100%;
}

.editor-toolbar button,
.editor-toolbar .easymde-dropdown {
    @apply bg-none h-8 m-0 p-0 inline-flex items-center justify-center cursor-pointer no-underline border-0 text-center rounded;
    /* background: 0 0; */
}

.editor-toolbar button {
    @apply w-8;
}

.editor-toolbar button:not(:disabled).active,
.editor-toolbar button:not(:disabled):hover {
    @apply bg-gray-300;
}

.editor-toolbar button:disabled {
    opacity: .6;
    pointer-events: none;
}

.editor-toolbar button.active,
.editor-toolbar button:focus {
    @apply bg-gray-300 outline-none ring-2 ring-blue-500;
}

.editor-toolbar i.separator {
    /* border-left: 1px solid #d9d9d9;
    border-right: 1px solid #fff; */
    @apply inline-block text-transparent w-0 m-0 border border-gray-200 border-r-0;
    text-indent: -10px;
}

.editor-toolbar button:after {
    @apply relative align-text-bottom;
}

.editor-toolbar button.loading::after {
    @apply border-blue-500;
}

.editor-toolbar button.heading-1:after {
    content: "1";
}

.editor-toolbar button.heading-2:after {
    content: "2";
}

.editor-toolbar button.heading-3:after {
    content: "3";
}

.editor-toolbar button.heading-bigger:after {
    content: "?";
}

.editor-toolbar button.heading-smaller:after {
    content: "?";
}

.editor-toolbar.disabled-for-preview button:not(.no-disable) {
    opacity: .6;
    pointer-events: none;
}

@media only screen and (max-width: 700px) {
    .editor-toolbar i.no-mobile {
        display: none;
    }
}

.editor-statusbar {
    padding: 8px 10px;
    font-size: 12px;
    color: #959694;
    text-align: right;
}

.EasyMDEContainer.sided--no-fullscreen .editor-statusbar {
    width: 100%;
}

.editor-statusbar span {
    display: inline-block;
    min-width: 4em;
    margin-left: 1em;
}

.editor-statusbar .lines:before {
    content: 'lines: '
}

.editor-statusbar .words:before {
    content: 'words: '
}

.editor-statusbar .characters:before {
    content: 'characters: '
}

.editor-preview-full {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 15;
    background-color: #ffffff;
    overflow: auto;
    display: none;
    box-sizing: border-box;
}

.editor-preview-side {
    position: fixed;
    bottom: 0;
    width: 50%;
    top: 50px;
    right: 0;
    z-index: 9;
    overflow: auto;
    display: none;
    box-sizing: border-box;
    border: 1px solid #ddd;
    word-wrap: break-word;
}

.editor-preview-active-side {
    display: block
}

.EasyMDEContainer.sided--no-fullscreen .editor-preview-active-side {
    flex: 1 1 auto;
    height: auto;
    position: static;
}

.editor-preview-active {
    display: block
}

.editor-preview {
    padding: 10px;
    background: #fafafa;
}

.editor-preview > p {
    margin-top: 0
}

.editor-preview pre {
    background: #eee;
    margin-bottom: 10px;
}

.editor-preview table td,
.editor-preview table th {
    border: 1px solid #ddd;
    padding: 5px;
}

.cm-s-easymde .cm-tag {
    color: #63a35c;
}

.cm-s-easymde .cm-attribute {
    color: #795da3;
}

.cm-s-easymde .cm-string {
    color: #183691;
}

.cm-s-easymde .cm-header-1 {
    font-size: 200%;
    line-height: 200%;
}

.cm-s-easymde .cm-header-2 {
    font-size: 160%;
    line-height: 160%;
}

.cm-s-easymde .cm-header-3 {
    font-size: 125%;
    line-height: 125%;
}

.cm-s-easymde .cm-header-4 {
    font-size: 110%;
    line-height: 110%;
}

.cm-s-easymde .cm-comment {
    background: rgba(0, 0, 0, .05);
    border-radius: 2px;
}

.cm-s-easymde .cm-link {
    color: #7f8c8d;
}

.cm-s-easymde .cm-url {
    color: #aab2b3;
}

.cm-s-easymde .cm-quote {
    color: #7f8c8d;
    font-style: italic;
}

.editor-toolbar .easymde-dropdown {
    position: relative;
    background: linear-gradient(to bottom right, #fff 0%, #fff 84%, #333 50%, #333 100%);
    border-radius: 0;
    border: 1px solid #fff;
}

.editor-toolbar .easymde-dropdown:hover {
    background: linear-gradient(to bottom right, #fff 0%, #fff 84%, #333 50%, #333 100%);
}

.easymde-dropdown-content {
    display: block;
    visibility: hidden;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 8px;
    z-index: 2;
    top: 30px;
}

.easymde-dropdown:active .easymde-dropdown-content,
.easymde-dropdown:focus .easymde-dropdown-content {
    visibility: visible;
}

span[data-img-src]::after{
    content: '';
    background-image: var(--bg-image);
    display: block;
    max-height: 100%;
    max-width: 100%;
    background-size: contain;
    height: 0;
    padding-top: var(--height);
    width: var(--width);
    background-repeat: no-repeat;
}
