/* Toastr2 modifications */
#toast-container > .toast:not(.toast-image) {
    padding-right: 2.5rem;
}

#toast-container > div {
    width: auto;
    padding: 15px 15px 15px 60px;
    background-position: 20px center;
}

.toast-close-button {
    position: absolute;
    /* top: 5px;
    right: 10px; */
    top: .6em;
    right: .6em;
    &:focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
    }
}

.toast-title {
    white-space: nowrap;
}

#toast-container > .toast-image.toast-info {
    background-image: none !important;
    padding: 0;
    /*width: 400px;*/
    .toast-message {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .toast--image {
            padding-right: 10px;
            object-fit: cover;
            width: 80px;
            height: 72px;
        }
        .toast--content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .toast--title {
                margin: 5px 0;
                font-weight: bold;
            }
            .toast--message {
                max-width: 240px;
                word-wrap: break-word;
            }
        }
    }
    /*
    background-position: 0 center;
    .toast-message {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    */
}
