.micromodal {
    /* display: none; */
    visibility: hidden;
    @apply opacity-0 transition-opacity duration-150;
}

.micromodal.is-open {
    /* display: block; */
    visibility: visible;
    @apply opacity-100 transition-opacity duration-300;
}

.micromodal-close:after {
    content: "\2715";
}

.micromodal.micromodal-slideover {
    transition: 300ms ease-in-out all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.micromodal.micromodal-slideover .micromodal-bg {
    transition: 100ms ease-in-out all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.micromodal.micromodal-slideover .micromodal-content {
    transition: 300ms ease-in-out all;
    transition-property: transform;
}

.micromodal.micromodal-slideover .micromodal-content.animation-right  {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate3d(100%,0,0);
}
.micromodal.is-open.micromodal-slideover .micromodal-content.animation-right {
    transform: translate3d(0,0,0);
}

.micromodal.micromodal-slideover .micromodal-content.animation-bottom {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate3d(0, 200%, 0);
}
.micromodal.is-open.micromodal-slideover .micromodal-content.animation-bottom {
    transform: translate3d(0, 0, 0);
}
