.select2--link {
    @apply p-2 text-right bg-gray-300 font-semibold;
    a {
        @apply text-blue-700;
        &:hover {
            @apply text-blue-900;
        }
    }
}

.select2-container .select2-selection--multiple {
    .select2-search__field {
        @apply p-2 m-0 w-auto;
    }
}

.select2-container {
    @apply static;

    &:not(.select2-container--disabled) {
        &.select2-container--focus .select2-selection,
        &.select2-container--open .select2-selection {
            @apply outline-none ;
        }
    }

    &.select2-container--open {
        .select2-dropdown {
            @apply flex flex-col outline-none shadow-lg;
            z-index: 9999;
        }
    }
}

.select2-container .select2-selection--single .select2-selection__arrow b {
    border-color: #212529 transparent transparent transparent;
    border-width: .3rem .3rem 0 .3rem;
    margin-left: -.3rem;
}

.select2-container.select2-container--open .select2-selection .select2-selection__arrow b {
    border-color: transparent transparent #212529 transparent;
    border-width: 0 .3rem .3rem .3rem;
}

.select2-container:not(.select2-container--disabled) {
    .select2-selection:hover {
        background-color: #fff !important;
    }
    .select2-selection--single {
        .select2-selection__rendered:hover {
            background-color: #fff !important;
        }
    }
    .select2-selection--multiple {
        @apply flex items-center gap-2 border border-gray-300 !p-0;

        .select2-search__field {
            @apply m-0 w-auto p-0
        }

        .select2-selection__rendered {
            @apply inline-flex items-center p-0 m-0;
            .select2-selection__choice {
                @apply !flex flex-row-reverse items-center justify-between font-medium text-xs p-1 !pt-[2px] !my-0 !mx-1;
                &:hover {
                    @apply bg-gray-300;
                }
            }
        }
        .select2-selection__choice__remove {
            @apply relative !p-0 !m-0 border-0 rounded-sm bg-transparent text-gray-500 cursor-pointer text-base leading-4 font-semibold;
            &:hover {
                @apply text-gray-800;
            }
            /* padding: 0 4px; */
            /* position: absolute; */
            /* left: 0; */
            /* top: 0; */
        }
        .select2-search--inline {
            @apply inline-flex items-center py-2;

            .select2-search__field {
                @apply box-border border-0 m-0 p-0 max-w-full resize-none align-bottom overflow-hidden h-5 text-sm leading-5;

                word-break: keep-all;
            }
        }
    }
}
.select2-container--default {
    .select2-selection--multiple .select2-selection__choice__remove {
        @apply mr-1;
    }
}
.select2-container {
    .select2-selection {
        @apply bg-white;
    }

    .select2-results__option {
        font-size: .85rem;
    }

    .select2-selection--single {
        @apply text-gray-900 py-2 align-middle bg-white rounded appearance-none font-normal border border-gray-300;

        &:not(.input-sm) {
            @apply leading-5;
            .select2-selection__rendered {
                @apply mt-0 leading-6 px-0;
            }
        }
    }
}

.select2-container .select2-results__option {
    padding: .45rem .75rem;
}

.select2-container .select2-results__option .select2-results__option {
    padding: .45rem .75rem;
}

.select2-container .select2-results__option[aria-selected=true] {
    @apply text-gray-900 bg-gray-200;

    &:hover {
        @apply text-gray-900 bg-gray-300;
    }
}

.select2-container .select2-results__option--highlighted[aria-selected] {
    @apply text-gray-900 bg-gray-200;
}

.select2-container--default .select2-results__option--selected {
    @apply bg-gray-300;
}

select.is-invalid ~ .select2-container {
    .select2-selection--single:focus {
        @apply outline-none ring-1 ring-red-500 border-red-500;
    }

    .select2-dropdown,
    .select2-selection {
        border-color: #dc3545;
    }

    &:not(.select2-container--focus):not(.select2-container--disabled) .select2-selection:hover {
        border-color: #dc3545;
    }

    &.select2-container--focus .select2-selection,
    &.select2-container--open .select2-selection {
        box-shadow: 0 0 0 0.2rem lighten(#dc3545, 30%);
        border-color: darken(#dc3545, 10%);
    }

    &.select2-drop-active {
        border-color: darken(#dc3545, 10%);

        &.select2-drop.select2-drop-above {
            border-top-color: darken(#dc3545, 10%);
        }
    }
}

.select2-container.is-invalid .select2-dropdown {
    .select2-dropdown--below {
        @apply border-t-0;
    }

    .select2-dropdown--above {
        @apply border-b-0;
    }
}

.select2-container:not(.select2-container--disabled).select2-container--focus .select2-selection,
.select2-container:not(.select2-container--disabled).select2-container--open .select2-selection {
    @apply outline-none;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    @apply bg-gray-200 pointer-events-none cursor-default;
}

.select2-container--default {
    .select2-selection--single {
        @apply pr-8 pl-3 appearance-none border stroke-current text-blue-600 rounded text-base h-auto bg-no-repeat;
        /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e"); */
        /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'/%3E%3C/svg%3E"); */
        /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e"); */
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
        background-position: right 0.5rem center;
        background-size: 1.5em 1.5em;
        print-color-adjust: exact;

        &:focus {
            @apply outline-none ring-1 ring-gray-700 border-gray-700;
        }
        .select2-selection__rendered {
            @apply pr-2 leading-5;
        }

        .select2-selection__clear {
            @apply relative float-right text-gray-500 cursor-pointer font-normal text-2xl leading-6 !h-6 mr-2 -mt-1;

            &:hover {
                @apply text-gray-700;
            }
            &:focus {
                @apply outline-none;
            }
        }
    }

    .select2-search--dropdown .select2-search__field {
        /* appearance: none; */
        /* border: 1px solid #e2e8f0;
        background-color: #fff;
        border-width: 1px; */
        @apply py-[4px] px-3 rounded-full text-base border border-gray-300;
        @apply !pl-10 text-gray-400;
        /* background-image: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E); */
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'%3E%3C/path%3E%3C/svg%3E");
        background-position: left 0.5rem center;
        background-repeat: no-repeat;
        background-size: 1.5em 1.5em;

        &:focus {
            @apply outline-none ring-1 ring-gray-700;
        }

    }

}

.select2-selection__arrow {
    display: none;
}

.select2-results__option.select2-results__message {
    @apply mb-1 text-xs text-gray-500;

}
